import redirectsData from '@/middleware/redirects.json';
import redirectsToIndexData from '@/middleware/redirects-to-index.json';

export default function ({ store, redirect, $cookies, $auth, getRouteBaseName, route, localePath }) {
    // redirects
    if (process.server) {
        const redirectUrl = redirectsData.data.find((data) => data.old === route.fullPath);

        const redirectUrlToIndex = redirectsToIndexData.data.find((x) => {
            const path = x.url.replace(/https?:\/\/(www\.)?naturadent.hu/, '');

            return path === route.fullPath;
        });

        if (redirectUrl) {
            return redirect(301, redirectUrl.new);
        } else if (redirectUrlToIndex) {
            return redirect(301, localePath('/'));
        }
    }

    // auth
    if (store.state.auth.loggedIn && !$cookies.get('__naturadent__keep_alive')) {
        $auth.logOut();
        return;
    }

    if (store.state.auth.loggedIn) {
        $cookies.set('__naturadent__keep_alive', true, {
            maxAge: $cookies.get('__naturadent__remember_me') ? 60 * 60 * 24 * 365 : 60 * 60 * 2,
        });
    }

    if (!getRouteBaseName()) {
        return;
    }

    if (store.state.auth.loggedIn && getRouteBaseName() === 'forgot-password') {
        return redirect('/');
    }

    if (!store.state.auth.loggedIn && getRouteBaseName().match(/^account-/)) {
        return redirect('/');
    }
}
