import jwtDecode from 'jwt-decode';

export default (ctx, inject) => {
    const $auth = {
        getToken() {
            return ctx.$cookies.get('__naturadent__access_token');
        },
        setToken(token) {
            ctx.$cookies.set('__naturadent__access_token', token);
            console.log(ctx.$dayjs().format('HH:mm ss.SSS') + '   new token: ' + token);
            ctx.$axios.setHeader('Authorization', token ? 'Bearer ' + token : '');
        },
        updateUser(payload) {
            ctx.store.commit('auth/updateUser', payload);
        },
        setUser(user) {
            ctx.store.commit('auth/setUser', user);
            ctx.store.commit('auth/setLoggedInStatus', !!user);
        },
        logIn(user, token) {
            this.setUser(user);
            this.setToken(token);
            ctx.$cookies.set('__naturadent__keep_alive', true, {
                maxAge: ctx.$cookies.get('__naturadent__remember_me') ? 60 * 60 * 24 * 365 : 60 * 60 * 2,
            });
            if (ctx.getRouteBaseName() && ctx.getRouteBaseName() === 'forgot-password') {
                return ctx.redirect('/');
            }
        },
        logOut() {
            ctx.$axios.$post('/auth/logout').then(() => {
                this.setToken('');
                this.setUser(null);
                ctx.$cookies.remove('__naturadent__keep_alive');
                return ctx.redirect('/');
            });
        },
        async refreshToken() {
            let decodedAccessToken;
            if (this.getToken()) {
                decodedAccessToken = jwtDecode(this.getToken());
            }
            // refresh token 1m before token is expired, just to make it sure
            // console.log('****************************************');
            if (!decodedAccessToken || Date.now() >= (decodedAccessToken.exp - 5) * 1000) {
                return await ctx.$axios
                    .$post('/auth/refresh-token')
                    .then((res) => {
                        this.setToken(res.token);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }

            return new Promise((resolve) => {
                resolve(null);
            });
        },
    };

    const token = ctx.$cookies.get('__naturadent__access_token');
    ctx.$axios.setHeader('Authorization', token ? 'Bearer ' + token : '');

    // $auth.setToken(ctx.$cookies.get())

    inject('auth', $auth);
    ctx.$auth = $auth;
};
