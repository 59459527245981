<template>
    <div>
        <span class="block text-center subtitle mb-10"><T t="account.register_account" /></span>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }" slim>
            <form novalidate class="w-full" @submit.prevent="handleSubmit(onSubmit)">
                <div class="grid grid-cols-12 gap-6">
                    <ValidationProvider
                        v-if="$i18n.locale === 'en'"
                        v-slot="{ errors }"
                        vid="first_name"
                        tag="div"
                        class="p-field col-span-12 lg:col-span-6"
                    >
                        <InputLabel for="first_name"><T t="form.first_name" /></InputLabel>
                        <InputText
                            id="first_name"
                            v-model="first_name"
                            name="first_name"
                            type="text"
                            :placeholder="$t('form.first_name')"
                            autocomplete="off"
                        />
                        <InputError :errors="errors" />
                    </ValidationProvider>

                    <ValidationProvider
                        v-slot="{ errors }"
                        vid="last_name"
                        tag="div"
                        class="p-field col-span-12 lg:col-span-6"
                    >
                        <InputLabel for="last_name"><T t="form.last_name" /></InputLabel>
                        <InputText
                            id="name"
                            v-model="last_name"
                            name="last_name"
                            type="text"
                            :placeholder="$t('form.last_name')"
                            autocomplete="off"
                        />
                        <InputError :errors="errors" />
                    </ValidationProvider>

                    <ValidationProvider
                        v-if="$i18n.locale === 'hu'"
                        v-slot="{ errors }"
                        vid="first_name"
                        tag="div"
                        class="p-field col-span-12 lg:col-span-6"
                    >
                        <InputLabel for="first_name"><T t="form.first_name" /></InputLabel>
                        <InputText
                            id="first_name_hu"
                            v-model="first_name"
                            name="first_name"
                            type="text"
                            :placeholder="$t('form.first_name')"
                            autocomplete="off"
                        />
                        <InputError :errors="errors" />
                    </ValidationProvider>

                    <ValidationProvider v-slot="{ errors }" vid="email" tag="div" class="p-field col-span-12">
                        <InputLabel for="email"><T t="account.your_email_address" /></InputLabel>
                        <InputText
                            id="email"
                            v-model="email"
                            name="email"
                            type="email"
                            :placeholder="$t('account.your_email_address')"
                            autocomplete="off"
                        />
                        <InputError :errors="errors" />
                    </ValidationProvider>

                    <ValidationProvider v-slot="{ errors }" vid="password" tag="div" class="p-field col-span-12">
                        <InputLabel for="password"><T t="account.your_password" /></InputLabel>
                        <Password
                            id="password"
                            v-model="password"
                            name="password"
                            toggle-mask
                            :feedback="false"
                            :placeholder="$t('account.your_password')"
                            autocomplete="off"
                        />
                        <InputError :errors="errors" />
                    </ValidationProvider>

                    <div class="col-span-12">
                        <ValidationProvider v-slot="{ errors }" vid="accept_privacy_policy" tag="div" class="mb-2">
                            <div class="p-field field-checkbox">
                                <Checkbox id="accept_privacy_policy" v-model="accept_privacy" :binary="true" />
                                <label for="accept_privacy_policy">
                                    <T t="form.data_privacy.text_1" />
                                    <NuxtLink
                                        :to="localePath('privacy-policy')"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        class="p-link"
                                    >
                                        <T t="form.data_privacy.text_2" />
                                    </NuxtLink>
                                    <T t="form.data_privacy.text_3" />
                                    <NuxtLink
                                        :to="localePath('terms-and-conditions')"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        class="p-link"
                                    >
                                        <T t="form.data_privacy.text_4" />
                                    </NuxtLink>
                                    <T t="form.data_privacy.text_5" />
                                </label>
                            </div>
                            <InputError :errors="errors" />
                        </ValidationProvider>

                        <ValidationProvider
                            v-slot="{ errors }"
                            vid="newsletter"
                            tag="div"
                            class="p-field field-checkbox"
                        >
                            <Checkbox id="newsletter-subscribe" v-model="newsletter" :binary="true" />
                            <label for="newsletter-subscribe"> <T t="newsletter.subscribe_label" /> </label>
                            <InputError :errors="errors" />
                        </ValidationProvider>
                    </div>

                    <div class="col-span-12 text-center mt-4">
                        <Button type="submit" :label="$t('account.register_account')" :disabled="submitting" />

                        <div class="mt-12">
                            <p><T t="account.do_you_have_naturadent_account" /></p>
                            <Button :label="$t('navigation.sign_in')" class="p-button-text" @click="toggle" />
                        </div>
                    </div>
                </div>
            </form>
        </ValidationObserver>
        <RecaptchaDisclaimer />
    </div>
</template>

<script>
import RecaptchaDisclaimer from '@/components/Forms/RecaptchaDisclaimer';

export default {
    name: 'RegisterForm',
    components: { RecaptchaDisclaimer },
    props: {
        toggle: {
            type: Function,
            required: true,
        },
        cb: {
            type: Function,
            required: false,
            default: () => {},
        },
    },
    data() {
        return {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            accept_privacy: false,
            newsletter: false,
            submitting: false,
        };
    },
    async mounted() {
        await this.$recaptcha.init();
    },
    beforeDestroy() {
        this.$recaptcha.destroy();
    },
    methods: {
        async onSubmit() {
            this.submitting = true;
            try {
                let success = false;
                const token = await this.$recaptcha.execute('registration');

                await this.$axios
                    .$post(
                        '/recaptcha/validate/auth/register',
                        {
                            first_name: this.first_name,
                            last_name: this.last_name,
                            email: this.email,
                            newsletter_subscribe: this.newsletter,
                            password: this.password,
                            accept_privacy_policy: this.accept_privacy,
                        },
                        {
                            baseURL: '',
                            headers: {
                                'base-url': this.$axios.defaults.baseURL,
                                'recaptcha-token': token,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.success === 1) {
                            success = true;
                        } else {
                            this.$refs.form.setErrors(res.error);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                await this.$recaptcha.reset();

                if (!success) {
                    this.submitting = false;
                    return;
                }

                await this.$axios
                    .$post('/auth/login', {
                        email: this.email,
                        password: this.password,
                    })
                    .then((res) => {
                        if (res.success === 1) {
                            // currently the "remember me" functionality is not requested, but it's easily done
                            // by setting the following cookie's value to dynamic
                            this.$cookies.set('__naturadent__remember_me', true);

                            this.$auth.logIn(res.user, res.token);
                            this.cb();
                            success = true;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        this.submitting = false;
                    });
            } catch (err) {
                console.log(err);
            }
        },
    },
};
</script>

<style scoped></style>
