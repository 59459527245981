export default function ({ $axios, i18n, $config, app, auth, store, redirect, $toast, $cookies, $device }) {
    const AUTHENTICATED_REQUEST_URLS = ['/user/me'];

    $axios.interceptors.request.use(async (request) => {
        if (!$device.isSafari && !/^https/.test(request.url)) {
            request.headers.accept = 'image/webp';
        }
        if (AUTHENTICATED_REQUEST_URLS.includes(request.url)) {
            await app.$auth.refreshToken();
        }

        return request;
    });

    // $axios.interceptors.response.use((response) => {
    //     return response;
    // });

    $axios.onError((error) => {
        console.log(error.response.status + ' (' + error.response.statusText + ')');
        if ((error.response.status === 401 || error.response.status === 403) && store.state.auth.loggedIn) {
            app.$auth.setToken('');
            app.$auth.setUser(null);
            $cookies.remove('__naturadent__keep_alive');
            return redirect('/');
        }
        // $toast.add({
        //     summary: {
        //         icon: require('~/assets/images/icons/settings.svg'),
        //         message: 'Your changes have been saved',
        //     },
        //     life: 4000,
        // });
    });

    $axios.defaults.baseURL = $config.baseURL + '/api/' + i18n.locale;
}
