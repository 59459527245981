export const state = () => ({
    currentArticleHeight: 0,
    doesProgressBarHaveTransition: false,
});

export const mutations = {
    setCurrentArticleHeight(state, payload) {
        state.currentArticleHeight = payload;
    },
    setDoesProgressBarHaveTransition(state, payload) {
        state.doesProgressBarHaveTransition = payload;
    },
};
