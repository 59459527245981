export const state = () => ({
    contactData: {
        en: [],
        hu: [],
    },
    services: {
        en: [],
        hu: [],
    },
    aboutUsMenuContentPages: {
        en: [],
        hu: [],
    },
    notifications: {
        en: [],
        hu: [],
    },
});

export const mutations = {
    setServices(state, payload) {
        state.services[payload.lang] = payload.data;
    },
    setContactData(state, payload) {
        state.contactData[payload.lang] = payload.data;
    },
    setAboutUsMenuContentPages(state, payload) {
        state.aboutUsMenuContentPages[payload.lang] = payload.data;
    },
    setNotifications(state, payload) {
        state.notifications[payload.lang] = payload.data;
    },
};
