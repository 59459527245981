<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
        <path
            d="M8.15885 24.167C10.5286 24.167 12.7066 23.6591 14.6927 22.6433C16.4983 21.7265 18.0781 20.4258 19.4323 18.741C20.6962 17.1801 21.6779 15.3963 22.3776 13.3894C23.0321 11.4321 23.3594 9.47476 23.3594 7.51745L23.3255 6.73701C24.3863 5.91939 25.2778 4.91596 26 3.72671C25.0295 4.19746 24.0139 4.50716 22.9531 4.65581C23.4948 4.28417 23.9688 3.81962 24.375 3.26216C24.7812 2.7047 25.0859 2.0791 25.2891 1.38537C24.2283 2.0791 23.0998 2.56223 21.9036 2.83477C21.4071 2.24014 20.8203 1.78179 20.1432 1.4597C19.4661 1.13761 18.7552 0.976562 18.0104 0.976562C17.0399 0.976562 16.1484 1.23671 15.3359 1.75701C14.5234 2.27731 13.8746 2.98962 13.3893 3.89395C12.9041 4.79828 12.6615 5.77074 12.6615 6.81133C12.6615 7.28208 12.7066 7.72805 12.7969 8.14924C10.6302 8.05014 8.57639 7.44312 6.63542 6.3282C4.76215 5.2876 3.14844 3.86298 1.79427 2.05432C1.32031 2.94626 1.08333 3.92492 1.08333 4.99029C1.08333 5.98133 1.29774 6.91044 1.72656 7.7776C2.15538 8.64476 2.7309 9.3385 3.45312 9.85879C2.59549 9.83402 1.79427 9.58626 1.04948 9.11551V9.18984C1.04948 10.6021 1.45573 11.8471 2.26823 12.9248C3.08073 14.0026 4.09635 14.6777 5.3151 14.9503C4.86372 15.0742 4.40104 15.1361 3.92708 15.1361C3.58854 15.1361 3.25 15.1113 2.91146 15.0618C3.25 16.2263 3.8763 17.1863 4.79036 17.942C5.70443 18.6977 6.73698 19.0879 7.88802 19.1127C6.96267 19.9055 5.93576 20.5249 4.80729 20.9709C3.67882 21.4168 2.50521 21.6398 1.28646 21.6398C0.857639 21.6151 0.428819 21.5779 0 21.5283C1.19618 22.3707 2.48264 23.0149 3.85938 23.4609C5.25868 23.9316 6.69184 24.167 8.15885 24.167Z"
            :fill="color"
        />
    </svg>
</template>

<script>
export default {
    name: 'TwitterIcon',
    props: {
        color: {
            type: String,
            required: false,
        },
    },
};
</script>

<style scoped></style>
