import Vue from 'vue';

import InputError from '@/components/UI/Inputs/InputError.vue';
import InputLabel from '@/components/UI/Inputs/InputLabel.vue';
import Breadcrumb from '@/components/Generic/Breadcrumb';
import T from '@/components/UI/T/T';

Vue.component('InputError', InputError);
Vue.component('InputLabel', InputLabel);
Vue.component('Breadcrumb', Breadcrumb);
Vue.component('T', T);
