<template>
    <div id="__layout__content" class="flex flex-col min-h-screen">
        <main>
            <Nuxt class="relative flex-grow pt-28" />
        </main>

        <div class="bg-dark-700 pt-6 pb-24 md:pb-6">
            <div class="container flex flex-col md:flex-row justify-center">
                <div class="flex flex-wrap">
                    <object
                        class="mx-auto mb-4 md:mb-0 md:mr-6 w-[46px] h-auto"
                        type="image/svg+xml"
                        title="Naturadent"
                        :data="require('~/assets/images/logos/NaturaDent-logo.svg')"
                    ></object>
                    <span class="text-white text-xs text-center md:text-left">
                        © Naturadent - {{ $t('generic.aesthetical_and_laser_dentistry') }} - 2008 -
                        {{ $dayjs().format('YYYY') }} {{ $t('generic.all_rights_reserved') }}.
                    </span>
                </div>
            </div>
        </div>

        <CookieBanner v-if="!$device.isCrawler" is-compact class="z-40" />

        <GridOverlay v-if="!$device.isCrawler" />

        <Toast v-if="!$device.isCrawler" position="top-right">
            <template #message="slotProps">
                <div>{{ slotProps.message.summary }}</div>
            </template>
        </Toast>

        <Portal v-if="!$device.isCrawler && false" selector="head">
            <script id="mcjs">
                !(function (c, h, i, m, p) {
                    (m = c.createElement(h)),
                        (p = c.getElementsByTagName(h)[0]),
                        (m.async = 1),
                        (m.src = i),
                        p.parentNode.insertBefore(m, p);
                })(
                    document,
                    'script',
                    'https://chimpstatic.com/mcjs-connected/js/users/b64995088e55e13cd1e028d01/ef8a1f4637ed153833fc9ce98.js'
                );
            </script>
        </Portal>
    </div>
</template>

<script>
import Toast from 'primevue/toast';
import GridOverlay from '@/components/Layout/GridOverlay';
import CookieBanner from '~/components/Layout/CookieBanner.vue';
import i18n from '@/mixins/i18n';

export default {
    name: 'Default',
    components: {
        Toast,
        GridOverlay,
        CookieBanner,
    },
    mixins: [i18n],
};
</script>
