import Vue from 'vue';

Vue.mixin({
    computed: {
        ww() {
            return this.$store.state.globals.windowWidth;
        },
        loggedIn() {
            return this.$store.state.auth.loggedIn;
        },
        $user() {
            return this.$store.state.auth.user;
        },
        $locale() {
            return this.$i18n.locale === 'hu' ? 'hu-HU' : 'en-EN';
        },
    },
    methods: {
        getFullName(user) {
            return `${user.last_name} ${user.first_name}`;
        },
        copy(array) {
            return JSON.parse(JSON.stringify(array));
        },
        getFormattedPrice(price) {
            const formattedPrice = new Intl.NumberFormat(this.$locale, {
                style: 'currency',
                currency: 'HUF',
                minimumFractionDigits: 0,
            }).format(price);

            if (this.$locale === 'en-EN') {
                const normalizedPrice = formattedPrice.replace(/\u00A0/g, ' ');
                const parts = normalizedPrice.split(' ');
                const amount = parts[1].replace(/,/g, ' ');
                const currency = parts[0];
                return `${amount} ${currency}`;
            } else {
                return formattedPrice;
            }
        },
        getOgTitle(title) {
            return `${title} - NaturaDent`;
        },
    },
});
