<template>
    <div class="bg-light-150">
        <div class="container">
            <div class="grid grid-cols-12">
                <div
                    class="
                        col-span-12
                        md:col-span-8 md:col-start-3
                        text-center
                        py-40
                        min-h-[calc(100vh-6.875rem)]
                        flex flex-col
                        items-center
                        justify-center
                    "
                >
                    <h2 class="text-green-500 text-8xl font-bold mb-4">{{ error.statusCode }}</h2>
                    <h1 v-if="error.statusCode === 404" class="title"><T t="errors.page_not_found" /></h1>
                    <h2 v-else>An error occurred - {{ error.statusCode }}</h2>
                    <h3 class="mt-2 font-HKGrotesk-SemiBold">
                        Forduljon kollégáinkhoz bizalommal, foglaljon időpontot!
                    </h3>
                    <div class="flex justify-center flex-col lg:flex-row lg:gap-y gap-y-4 gap-x-8 mt-8">
                        <Button :label="$t('generic.appointment_booking')" @click="openAppointmentBookingModal" />
                        <ButtonLink
                            to="/"
                            :label="$t('errors.back_to_homepage')"
                            class="p-component p-button p-button-outlined w-full lg:w-max"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Error',
    layout: 'Default',
    props: {
        error: {
            type: Object,
            required: true,
        },
    },
    methods: {
        openAppointmentBookingModal() {
            const gtagAttributes = {
                page_path: this.$route.path,
                variable_1: '5678',
                variable_3: true,
            };

            this.$store.commit('layout/setIsAppointmentBookingOpen', true);
            document.body.classList.add('overflow-hidden');
            if (this.$i18n.locale === 'hu') {
                this.$router.push({ hash: '#idopontfoglalas' });
            } else {
                this.$router.push({ hash: '#appointment-booking' });
            }

            this.$gtm.push({ event: 'form_start', ...gtagAttributes });
        },
    },
};
</script>
