<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="27" viewBox="0 0 24 27" fill="none">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M23.3523 25.5378C22.9205 25.993 22.4091 26.2206 21.8182 26.2206H2.18182C1.59091 26.2206 1.07955 25.993 0.647727 25.5378C0.215909 25.0826 0 24.5436 0 23.9207V3.22176C0 2.59887 0.215909 2.05984 0.647727 1.60465C1.07955 1.14947 1.59091 0.921875 2.18182 0.921875H21.8182C22.4091 0.921875 22.9205 1.14947 23.3523 1.60465C23.7841 2.05984 24 2.59887 24 3.22176V23.9207C24 24.5436 23.7841 25.0826 23.3523 25.5378ZM2 24.1124H12.7639V16.2066H9.84722V12.6929H12.7639V10.0576C12.7639 8.56913 13.1516 7.42229 13.9271 6.61707C14.7025 5.81184 15.75 5.40923 17.0694 5.40923C18.25 5.40923 19.1181 5.44583 19.6736 5.51903V8.70333H17.8681C17.2199 8.70333 16.7685 8.86194 16.5139 9.17915C16.3287 9.42316 16.2361 9.83797 16.2361 10.4236V12.6929H19.5347L19.1181 16.2066H16.2361V24.1124H22V3.03015H2V24.1124Z"
            :fill="color"
        />
    </svg>
</template>

<script>
export default {
    name: 'FacebookIcon',
    props: {
        color: {
            type: String,
            default: '#113E51',
            required: false,
        },
    },
};
</script>

<style scoped></style>
