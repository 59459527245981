export const state = () => ({});

export const mutations = {};

export const actions = {
    async fetchEssentialData(context) {
        await Promise.allSettled([
            this.$axios.$get('/service/list'),
            this.$axios.$get('/contact-page'),
            this.$axios.$get('/content?is_menu=1'),
            this.$axios.$get('/notification'),
        ])
            .then(([servicesRes, contactDataRes, contentRes, notificationRes]) => {
                if (servicesRes.status === 'fulfilled' && servicesRes.value.success === 1) {
                    context.commit('data/setServices', { lang: this.$i18n.locale, data: servicesRes.value.data });
                }
                if (contactDataRes.status === 'fulfilled' && contactDataRes.value.success === 1) {
                    context.commit('data/setContactData', { lang: this.$i18n.locale, data: contactDataRes.value.data });
                }
                if (contentRes.status === 'fulfilled' && contentRes.value.success === 1) {
                    context.commit('data/setAboutUsMenuContentPages', {
                        lang: this.$i18n.locale,
                        data: contentRes.value.data,
                    });
                }
                if (notificationRes.status === 'fulfilled' && notificationRes.value.success === 1) {
                    context.commit('data/setNotifications', {
                        lang: this.$i18n.locale,
                        data: notificationRes.value.data,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    },
    async nuxtServerInit({ dispatch, commit, state }, { app, $auth }) {
        await dispatch('fetchEssentialData');

        if (!$auth.getToken()) {
            return;
        }

        await app.$axios
            .$get('/user/me')
            .then((res) => {
                if (res.success === 1) {
                    app.$auth.setUser(res.data);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    },
};
