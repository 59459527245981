<template>
    <header class="fixed top-0 left-0 justify-between items-center w-full">
        {{ /* DESKTOP, Primary menu */ }}
        <div v-if="$device.isDesktop" class="hidden xl:block shadow-lg transition-shadow box-shadow">
            <div class="relative bg-light-200 z-10">
                <div class="container flex items-center justify-between">
                    <div class="flex font-HKGrotesk-Medium text-xs">
                        <div v-if="contactData.phone" class="mr-8 flex items-center">
                            {{ $t('generic.laser_dentistry') }}
                            <a :href="`tel:${contactData.phone}`" rel="noopener noreferrer" class="hover:underline">
                                {{ contactData.phone }}
                            </a>
                        </div>
                        <div class="mr-8 flex items-center">
                            <T t="contact.opening_hours"></T>
                        </div>
                        <a
                            v-if="contactData.google_maps_url && contactData.dental_office"
                            :href="contactData.google_maps_url"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="hover:underline"
                        >
                            <span class="hover:underline" v-html="contactData.dental_office"></span>
                        </a>
                    </div>
                    <div class="flex items-center">
                        <button
                            name="Search"
                            aria-label="Search"
                            class="flex items-center mr-4"
                            @click="openSearchModal"
                        >
                            <i class="ni ni-magnifier"></i>
                        </button>

                        <LanguageSwitcher />

                        <NuxtLink :to="localePath('contact')" class="text-sm ml-4 py-2 hover:underline">
                            <T t="core.contact" />
                        </NuxtLink>
                        <NuxtLink :to="localePath('faq')" class="text-sm ml-4 py-2 hover:underline">
                            <T t="core.faq_short" />
                        </NuxtLink>
                    </div>
                </div>
            </div>
            <div class="relative bg-white z-5">
                <div class="container">
                    <div class="flex justify-between items-center w-full">
                        <div class="flex">
                            <NuxtLink :to="localePath('index')" class="flex items-center">
                                <object
                                    type="image/svg+xml"
                                    title="Naturadent"
                                    :data="require('~/assets/images/logos/NaturaDent-text-logo.svg')"
                                ></object>
                            </NuxtLink>
                            <div
                                v-if="services.length"
                                class="desktop-nav-dropdown relative ml-4 xl:ml-8"
                                @click="isDropdownVisible = false"
                            >
                                <NuxtLink class="nav-item py-6 header-dropdown-item" :to="localePath('services')">
                                    <span class="mr-2" @mouseenter="isDropdownVisible = true">
                                        <T t="core.services" />
                                    </span>
                                    <i class="ni ni-angle-arrow-down text-xs text-green-400 transition-transform"></i>
                                </NuxtLink>
                                <div
                                    v-show="isDropdownVisible"
                                    class="desktop-nav-dropdown__panel"
                                    @click="isDropdownVisible = false"
                                >
                                    <NuxtLink
                                        v-for="service in services"
                                        :key="`service-nav-link-${service.id}`"
                                        :to="localePath({ name: 'services', hash: `#${service.slug}` })"
                                    >
                                        <span @click="scrollToSection(service.slug)">
                                            {{ service.title }}
                                        </span>
                                    </NuxtLink>
                                </div>
                            </div>
                            <div
                                v-if="services.length"
                                class="desktop-nav-dropdown relative ml-4 xl:ml-8"
                                @click="isDropdownVisible = false"
                            >
                                <NuxtLink
                                    class="nav-item py-6 header-dropdown-item"
                                    :to="localePath('about-introduction')"
                                >
                                    <span class="mr-2" @mouseenter="isDropdownVisible = true">
                                        <T t="core.about" />
                                    </span>
                                    <i class="ni ni-angle-arrow-down text-xs text-green-400 transition-transform"></i>
                                </NuxtLink>
                                <div
                                    v-show="isDropdownVisible"
                                    class="desktop-nav-dropdown__panel"
                                    @click="isDropdownVisible = false"
                                >
                                    <NuxtLink
                                        v-for="contentPage in contentPages"
                                        :key="`content-page-link-${contentPage.id}`"
                                        :to="
                                            contentPage.slug === 'quality-guarantee'
                                                ? localePath({ name: 'quality-guarantee' })
                                                : localePath({
                                                      name: 'about-content',
                                                      params: { content: contentPage.slug },
                                                  })
                                        "
                                    >
                                        {{ contentPage.title }}
                                    </NuxtLink>
                                    <NuxtLink
                                        v-for="notification in notifications"
                                        :key="`notification-page-link-${notification.id}`"
                                        :to="
                                            localePath({
                                                name: 'about-notifications-notification',
                                                params: { notification: notification.slug },
                                            })
                                        "
                                    >
                                        {{ notification.title }}
                                    </NuxtLink>
                                    <NuxtLink :to="localePath('about-introduction')">
                                        <T t="core.introduction" />
                                    </NuxtLink>
                                    <NuxtLink :to="localePath('about-dentists')">
                                        <T t="core.our_team" />
                                    </NuxtLink>
                                    <NuxtLink :to="localePath('about-testimonials')">
                                        <T t="core.reviews_about_us" />
                                    </NuxtLink>
                                    <NuxtLink :to="localePath('about-trainings')">
                                        <T t="core.trainings" />
                                    </NuxtLink>
                                    <NuxtLink :to="localePath('about-career')">
                                        <T t="core.career" />
                                    </NuxtLink>
                                </div>
                            </div>
                            <NuxtLink :to="localePath('pricing')" class="py-6 ml-4 xl:ml-6 header-item">
                                <T t="core.prices" />
                            </NuxtLink>
                            <NuxtLink :to="localePath('dental-problems')" class="py-6 ml-4 xl:ml-6 header-item">
                                <T t="core.dental_problems" />
                            </NuxtLink>
                            <NuxtLink
                                v-if="!$route.params.article"
                                :to="localePath('blog')"
                                class="py-6 ml-4 xl:ml-6 header-item"
                            >
                                <T t="core.blog" />
                            </NuxtLink>
                            <NuxtLink v-else class="py-6 ml-4 xl:ml-6 header-item font-bold" :to="localePath('blog')"
                                >Blog
                            </NuxtLink>
                            <NuxtLink :to="localePath('works')" class="py-6 ml-4 xl:ml-6 header-item">
                                <T t="core.our_works" />
                            </NuxtLink>
                        </div>
                        <div class="flex">
                            <Button :label="$t('generic.appointment_booking')" @click="openAppointmentBookingModal" />
                            <Button
                                v-if="/*!loggedIn*/ false"
                                :label="$t('navigation.sign_in')"
                                class="ml-4"
                                @click="openLoginModal"
                            />
                            <div v-if="loggedIn" class="desktop-nav-dropdown relative ml-5">
                                <button
                                    name="Open my profile menu dropdown"
                                    aria-label="Open my profile menu dropdown"
                                    class="text-sm flex items-center py-4"
                                >
                                    <span class="w-10 h-10 rounded-full bg-green-200 mr-2"></span>
                                    <span class="mr-4">
                                        <T t="navigation.my_profile" />
                                    </span>
                                    <i class="ni ni-angle-arrow-down text-xs text-green-400 transition-transform"></i>
                                </button>
                                <div class="desktop-nav-dropdown__panel profile -right-4">
                                    <NuxtLink :to="localePath('account-dashboard')" class="font-HKGrotesk-Bold">
                                        <T t="navigation.dashboard" />
                                    </NuxtLink>
                                    <NuxtLink :to="localePath('account-appointments')" class="font-HKGrotesk-Bold">
                                        <T t="navigation.booked_appointments" />
                                    </NuxtLink>
                                    <NuxtLink :to="localePath('account-referral-program')" class="font-HKGrotesk-Bold">
                                        <T t="navigation.referral" />
                                    </NuxtLink>
                                    <div class="h-[1px] bg-light-300 my-2"></div>
                                    <NuxtLink :to="localePath('account-settings')">
                                        <T t="navigation.settings" />
                                    </NuxtLink>
                                    <button name="Log out" aria-label="Log out" @click="$auth.logOut()">
                                        <T t="navigation.sign_out" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BlogArticleReadProgressBar />
            </div>
        </div>

        {{ /* MOBILE menu */ }}
        <div :class="$device.isDesktop ? 'xl:hidden' : 'h-0'">
            {{ /* mobile menu background overlay */ }}
            <div
                class="fixed top-0 left-0 w-full h-full bg-black/70 transition-opacity z-5"
                :class="isMobileMenuOpen ? 'opacity-1 pointer-events-all' : 'opacity-0 pointer-events-none'"
                @click="closeMobileMenu"
            ></div>

            <div
                class="bg-light-150 shadow-md transition-transform z-0"
                :class="{ '-translate-y-13': !isUpperPartOfMobileNavVisible }"
            >
                {{ /* upper part of mobile menu */ }}
                <div class="bg-light-200 py-2 transition-all">
                    <div class="container flex items-center justify-between h-full">
                        <span><T t="generic.laser_dentistry" /></span>
                        <div class="overflow-hidden">
                            <Button
                                :label="$t('generic.appointment_booking')"
                                class="p-button-sm transition-all"
                                :class="{ 'translate-y-full opacity-0': !isUpperPartOfMobileNavVisible }"
                                @click.native="openAppointmentBookingModal"
                            />
                        </div>
                    </div>
                    <BlogArticleReadProgressBar />
                </div>

                {{ /* lower part of mobile menu */ }}
                <div class="container flex items-center justify-between h-16">
                    <NuxtLink :to="localePath('index')" class="mr-4">
                        <object
                            type="image/svg+xml"
                            title="Naturadent"
                            :data="require('~/assets/images/logos/NaturaDent-logo.svg')"
                            class="header-logo--icon-only"
                        ></object>
                        <object
                            type="image/svg+xml"
                            title="Naturadent"
                            :data="require('~/assets/images/logos/NaturaDent-text-logo.svg')"
                            class="header-logo--text"
                        ></object>
                    </NuxtLink>

                    <div class="flex items-center justify-end flex-grow">
                        <div class="overflow-hidden">
                            {{ /* visible only when page was scrolled down */ }}
                            <Button
                                :label="$t('generic.appointment_booking')"
                                class="p-button-sm mr-4 transition-all"
                                :class="{ '-translate-y-full opacity-0': isUpperPartOfMobileNavVisible }"
                                @click.native="openAppointmentBookingModal"
                            />
                        </div>

                        <button
                            class="text-3xl inline-flex items-center"
                            name="Toggle mobile menu"
                            aria-label="Toggle mobile menu"
                            @click="toggleMobileMenu"
                        >
                            <i class="ni ni-hamburger-menu"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div
                class="fixed top-0 right-0 w-11/12 xl:w-5/12 h-screen bg-light-150 z-10"
                :class="{ 'translate-x-full': !isMobileMenuOpen }"
                style="transition: transform 0.3s ease"
            >
                <div class="container flex items-center justify-between mt-5">
                    <NuxtLink :to="localePath('index')" @click.native="toggleMobileMenu">
                        <object
                            type="image/svg+xml"
                            title="Naturadent"
                            :data="require('~/assets/images/logos/NaturaDent-text-logo.svg')"
                            class="mobile-menu-logo"
                        ></object>
                    </NuxtLink>
                    <div class="flex items-center">
                        <div class="mr-2">
                            <LanguageSwitcher />
                        </div>
                        <button
                            class="text-3xl inline-flex items-center"
                            name="Toggle mobile menu"
                            aria-label="Toggle mobile menu"
                            @click="toggleMobileMenu"
                        >
                            <i class="ni ni-cross"></i>
                        </button>
                    </div>
                </div>

                <div
                    class="absolute w-full overflow-hidden bottom-0 left-0 xl:px-5 bg-white z-20"
                    style="height: calc(100% - 4.5rem)"
                >
                    <div
                        :ref="$device.isSafari ? '' : 'mobileMenuScrollContainer'"
                        class="container overflow-y-auto h-full"
                    >
                        <div class="pt-4 pb-24 z-20">
                            <div class="flex flex-col items-start">
                                <div class="grid grid-cols-2 gap-4 w-full mb-4">
                                    <div :class="loggedIn ? 'col-span-2' : 'col-span-1'">
                                        <Button
                                            :label="$t('generic.appointment_booking')"
                                            class="p-button-md/sm"
                                            :class="loggedIn ? 'xs-w-full' : 'w-full'"
                                            @click.native="openAppointmentBookingModal"
                                        />
                                    </div>
                                    <div v-if="/*!loggedIn*/ false" class="col-span-1">
                                        <Button
                                            :label="$t('navigation.sign_in')"
                                            class="w-full p-button-outlined p-button-md/sm"
                                            @click="handleMobileNavLoginButtonClick"
                                        />
                                    </div>
                                </div>

                                <SearchBlock
                                    type="slim"
                                    context="global"
                                    class="w-full mb-2"
                                    :submit-cb="toggleMobileMenu"
                                />

                                <NuxtLink
                                    v-if="loggedIn"
                                    class="mobile-nav-item"
                                    :to="localePath('account-dashboard')"
                                    active-class="text-green-400"
                                    @click.native="toggleMobileMenu"
                                >
                                    <T t="navigation.my_profile" />
                                </NuxtLink>

                                <button
                                    v-if="services.length"
                                    class="mobile-nav-item"
                                    :class="{
                                        'text-green-400': getRouteBaseName() && getRouteBaseName().match(/^services/),
                                    }"
                                    name="Open services menu"
                                    aria-label="Open services menu"
                                    @click="openMobileServicesSubmenu"
                                >
                                    <T t="core.services" />
                                </button>

                                <button
                                    class="mobile-nav-item"
                                    :class="{
                                        'text-green-400': getRouteBaseName() && getRouteBaseName().match(/^about/),
                                    }"
                                    @click="openMobileAboutSubmenu"
                                >
                                    <T t="core.about" />
                                </button>

                                <NuxtLink
                                    class="mobile-nav-item"
                                    :to="localePath('pricing')"
                                    active-class="text-green-400"
                                    @click.native="toggleMobileMenu"
                                >
                                    <T t="core.prices" />
                                </NuxtLink>

                                <NuxtLink
                                    class="mobile-nav-item"
                                    :to="localePath('dental-problems')"
                                    active-class="text-green-400"
                                    @click.native="toggleMobileMenu"
                                >
                                    <T t="core.dental_problems" />
                                </NuxtLink>

                                <NuxtLink
                                    class="mobile-nav-item"
                                    :to="localePath('blog')"
                                    active-class="text-green-400"
                                    @click.native="toggleMobileMenu"
                                >
                                    <T t="core.blog" />
                                </NuxtLink>

                                <NuxtLink
                                    class="mobile-nav-item"
                                    :to="localePath('works')"
                                    active-class="text-green-400"
                                    @click.native="toggleMobileMenu"
                                >
                                    <T t="core.our_works" />
                                </NuxtLink>

                                <NuxtLink
                                    class="mobile-nav-item"
                                    :to="localePath('contact')"
                                    active-class="text-green-400"
                                    @click.native="toggleMobileMenu"
                                >
                                    <T t="core.contact" />
                                </NuxtLink>

                                <NuxtLink
                                    class="mobile-nav-item"
                                    :to="localePath('faq')"
                                    active-class="text-green-400"
                                    @click.native="toggleMobileMenu"
                                >
                                    <T t="core.faq_short" />
                                </NuxtLink>

                                <button
                                    v-if="loggedIn"
                                    class="mobile-nav-item border-none"
                                    name="Log out"
                                    aria-label="Log out"
                                    @click="$auth.logOut()"
                                >
                                    <T t="navigation.sign_out" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="services.length"
                    class="
                        absolute
                        w-full
                        overflow-hidden
                        bottom-0
                        left-0
                        xl:px-5
                        z-20
                        bg-light-150
                        transition-transform
                    "
                    :class="{ 'translate-x-full': !isMobileServicesSubmenuOpen }"
                    style="height: calc(100% - 5rem)"
                >
                    <div class="container overflow-y-auto h-full">
                        <div class="pt-4 pb-24 z-20">
                            <div class="flex flex-col items-start">
                                <button
                                    name="Close services menu"
                                    aria-label="Close services menu"
                                    @click="closeMobileServicesSubmenu"
                                >
                                    <i class="ni ni-arrow-left text-2xl"></i>
                                </button>
                                <NuxtLink
                                    v-for="service in services"
                                    :key="`mobile-service-nav-link-${service.id}`"
                                    :to="localePath({ name: 'services', hash: `#${service.slug}` })"
                                    class="mobile-nav-item"
                                    active-class="text-green-400"
                                    @click.native="toggleMobileMenu"
                                >
                                    {{ service.title }}
                                </NuxtLink>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="
                        absolute
                        w-full
                        overflow-hidden
                        bottom-0
                        left-0
                        xl:px-5
                        z-20
                        bg-light-150
                        transition-transform
                    "
                    :class="{ 'translate-x-full': !isMobileAboutSubmenuOpen }"
                    style="height: calc(100% - 5rem)"
                >
                    <div class="container overflow-y-auto h-full">
                        <div class="pt-4 pb-24 z-20">
                            <div class="flex flex-col items-start">
                                <button
                                    name="Close about us menu"
                                    aria-label="Close about us menu"
                                    @click="closeMobileAboutSubmenu"
                                >
                                    <i class="ni ni-arrow-left text-2xl"></i>
                                </button>
                                <NuxtLink
                                    v-for="contentPage in contentPages"
                                    :key="`content-page-link-mobile-${contentPage.id}`"
                                    :to="localePath({ name: 'about-content', params: { content: contentPage.slug } })"
                                    class="mobile-nav-item"
                                    active-class="text-green-400"
                                    @click.native="toggleMobileMenu"
                                >
                                    {{ contentPage.title }}
                                </NuxtLink>
                                <NuxtLink
                                    v-for="notification in notifications"
                                    :key="`notification-page-link-mobile-${notification.id}`"
                                    :to="
                                        localePath({
                                            name: 'about-notifications-notification',
                                            params: { notification: notification.slug },
                                        })
                                    "
                                    class="mobile-nav-item"
                                    active-class="text-green-400"
                                    @click.native="toggleMobileMenu"
                                >
                                    {{ notification.title }}
                                </NuxtLink>
                                <NuxtLink
                                    :to="localePath('about-introduction')"
                                    class="mobile-nav-item"
                                    active-class="text-green-400"
                                    @click.native="toggleMobileMenu"
                                >
                                    <T t="core.introduction" />
                                </NuxtLink>
                                <NuxtLink
                                    :to="localePath('about-dentists')"
                                    class="mobile-nav-item"
                                    active-class="text-green-400"
                                    @click.native="toggleMobileMenu"
                                >
                                    <T t="core.our_team" />
                                </NuxtLink>
                                <NuxtLink
                                    :to="localePath('about-testimonials')"
                                    class="mobile-nav-item"
                                    active-class="text-green-400"
                                    @click.native="toggleMobileMenu"
                                >
                                    <T t="core.reviews_about_us" />
                                </NuxtLink>
                                <NuxtLink
                                    :to="localePath('about-trainings')"
                                    class="mobile-nav-item"
                                    active-class="text-green-400"
                                    @click.native="toggleMobileMenu"
                                >
                                    <T t="core.trainings" />
                                </NuxtLink>
                                <NuxtLink
                                    :to="localePath('about-career')"
                                    class="mobile-nav-item border-none"
                                    active-class="text-green-400"
                                    @click.native="toggleMobileMenu"
                                >
                                    <T t="core.career" />
                                </NuxtLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseModal :close="closeSearchModal" :is-open="$store.state.layout.isSearchModalOpen" is-wide>
            <div class="subtitle">
                <T t="generic.search_modal_title" />
            </div>
            <div>
                <SearchBlock
                    type="standard"
                    context="global"
                    :placeholder="$t('generic.search_modal_placeholder')"
                    :cancel-cb="closeSearchModal"
                    :submit-cb="filter"
                    focus-on-mount
                    :should-redirect-after-submit="true"
                />
                <div class="border-b border-light-350"></div>
            </div>
        </BaseModal>

        <BaseModal :close="closeLoginModal" :is-open="$store.state.layout.isLoginModalOpen">
            <RegisterForm v-if="isRegisterPanelVisible" :toggle="toggleRegisterPanelVisibility" :cb="closeLoginModal" />
            <LoginForm v-else :toggle="toggleRegisterPanelVisibility" :cb="closeLoginModal" />
        </BaseModal>

        <BaseModal :close="closeAppointmentBookingModal" :is-open="$store.state.layout.isAppointmentBookingOpen">
            <AppointmentBookingForm />
        </BaseModal>
    </header>
</template>

<script>
import { enableBodyScroll /* disableBodyScroll */ } from 'body-scroll-lock';
import { throttle } from 'throttle-debounce';
import LanguageSwitcher from '@/components/Generic/LanguageSwitcher';
import SearchBlock from '@/components/Sections/SearchBlock/SearchBlock';
import BaseModal from '@/components/Modals/BaseModal';
import LoginForm from '@/components/Forms/LoginForm';
import RegisterForm from '@/components/Forms/RegisterForm';
import BlogArticleReadProgressBar from '@/components/Generic/BlogArticleReadProgressBar';
import AppointmentBookingForm from '@/components/Forms/AppointmentBookingForm';

export default {
    name: 'Header',
    components: {
        BaseModal,
        LoginForm,
        RegisterForm,
        LanguageSwitcher,
        SearchBlock,
        BlogArticleReadProgressBar,
        AppointmentBookingForm,
    },
    data() {
        return {
            isMobileMenuOpen: false,
            isDropdownVisible: false,
            isMobileServicesSubmenuOpen: false,
            isMobileAboutSubmenuOpen: false,
            prevWindowWidth: null,
            isRegisterPanelVisible: false,
            resizeHandler: throttle(100, this.handleResize),
        };
    },
    computed: {
        services() {
            return this.$store.state.data.services[this.$i18n.locale];
        },
        isUpperPartOfMobileNavVisible() {
            return this.$store.state.globals.lastScrollDirection === 'up' || this.$store.state.globals.scrollY === 0;
        },
        contactData() {
            return this.$store.state.data.contactData[this.$i18n.locale];
        },
        contentPages() {
            return this.$store.state.data.aboutUsMenuContentPages[this.$i18n.locale];
        },
        notifications() {
            return this.$store.state.data.notifications[this.$i18n.locale];
        },
    },
    watch: {
        isMobileMenuOpen() {
            if (this.isMobileMenuOpen) {
                // disableBodyScroll(this.$refs.mobileMenuScrollContainer);
                document.body.classList.add('overflow-hidden');
            } else {
                // enableBodyScroll(this.$refs.mobileMenuScrollContainer);
                document.body.classList.remove('overflow-hidden');
            }
        },
        $route(to, from) {
            this.closeMobileMenu();
        },
    },
    mounted() {
        window.addEventListener('resize', this.resizeHandler);
        if (
            this.$route.hash === '#appointment-booking' ||
            this.$route.hash === '#idopontfoglalas' ||
            this.$route.hash === '#kapcsolatfelvetel' ||
            this.$route.hash === '#contact'
        ) {
            this.$store.commit('layout/setIsAppointmentBookingOpen', true);
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeHandler);
        enableBodyScroll(this.$refs.mobileMenuScrollContainer);
    },
    methods: {
        openLoginModal() {
            this.isRegisterPanelVisible = false;
            this.$store.commit('layout/setIsLoginModalOpen', true);
            document.body.classList.add('overflow-hidden');
        },
        closeLoginModal() {
            this.$store.commit('layout/setIsLoginModalOpen', false);
            document.body.classList.remove('overflow-hidden');
        },
        openSearchModal() {
            this.$store.commit('layout/setIsSearchModalOpen', true);
            document.body.classList.add('overflow-hidden');
        },
        closeSearchModal() {
            this.$store.commit('layout/setIsSearchModalOpen', false);
            document.body.classList.remove('overflow-hidden');
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        scrollToSection(sectionId) {
            const target = document.getElementById(sectionId);
            if (target) {
                const targetPosition = target.offsetTop;

                window.scrollTo({
                    top: targetPosition,
                    behavior: 'smooth',
                });
            }
        },
        openAppointmentBookingModal() {
            const gtagAttributes = {
                page_path: this.$route.path,
                variable_1: '5678',
                variable_3: true,
            };

            this.$store.commit('layout/setIsAppointmentBookingOpen', true);
            document.body.classList.add('overflow-hidden');
            if (this.$i18n.locale === 'hu') {
                this.$router.push({ hash: '#idopontfoglalas' });
            } else {
                this.$router.push({ hash: '#appointment-booking' });
            }

            this.$gtm.push({ event: 'form_start', ...gtagAttributes });
        },
        closeAppointmentBookingModal() {
            this.$store.commit('layout/setIsAppointmentBookingOpen', false);
            document.body.classList.remove('overflow-hidden');
            if (this.$i18n.locale === 'hu') {
                this.$router.push({ hash: '' });
            } else {
                this.$router.push({ hash: '' });
            }
        },
        filter(query) {
            this.$router.push(this.localePath({ name: 'search', query: { search: encodeURIComponent(query) } }));
            this.closeSearchModal();
        },
        toggleRegisterPanelVisibility() {
            this.isRegisterPanelVisible = !this.isRegisterPanelVisible;
        },
        openMobileMenu() {
            this.isMobileMenuOpen = true;
        },
        closeMobileMenu() {
            this.isMobileMenuOpen = false;
        },
        closeMobileServicesSubmenu() {
            this.isMobileServicesSubmenuOpen = false;
        },
        closeMobileAboutSubmenu() {
            this.isMobileAboutSubmenuOpen = false;
        },
        toggleMobileMenu() {
            this.isMobileMenuOpen = !this.isMobileMenuOpen;
            this.closeMobileServicesSubmenu();
            this.closeMobileAboutSubmenu();
        },
        handleResize() {
            if (window.innerWidth >= 768 && this.prevWindowWidth < 768) {
                this.isMobileMenuOpen = false;
                this.$nextTick(() => {
                    enableBodyScroll(this.$refs.mobileMenuScrollContainer);
                });
            } else if (window.innerWidth < 768 && this.prevWindowWidth >= 768) {
                this.closeMobileMenu();
            } else if (window.innerWidth < 1024 && this.prevWindowWidth >= 1024) {
                this.closeSearchModal();
            }

            this.prevWindowWidth = window.innerWidth;
        },
        handleMobileNavLoginButtonClick() {
            this.toggleMobileMenu();
            setTimeout(() => {
                this.openLoginModal();
            }, 100);
        },
        openMobileServicesSubmenu(e) {
            e.preventDefault();
            this.isMobileServicesSubmenuOpen = true;
        },
        openMobileAboutSubmenu(e) {
            e.preventDefault();
            this.isMobileAboutSubmenuOpen = true;
        },
    },
};
</script>

<style scoped>
.header-item {
    @apply hover:text-green-500 transition;
}

.header-item.nuxt-link-active {
    @apply font-bold;
}

.header-dropdown-item.nuxt-link-active {
    @apply font-bold;
}

.desktop-nav-dropdown__panel {
    opacity: 0;
    pointer-events: none;
    transform: translateY(calc(100% - 10px));
    transition: transform 0.2s, opacity 0.15s;
    @apply absolute -left-5 bottom-0 py-2 px-2 bg-white rounded text-black shadow-lg -mb-2;

    &::before {
        content: '';
        @apply w-full h-2 block absolute left-0 -top-2;
    }

    a,
    button {
        @apply block w-full text-left px-3 py-2 mb-1 rounded-md transition-colors hover:bg-green-100 whitespace-nowrap;
    }

    &.profile {
        a,
        button {
            padding-right: 4rem;
        }
    }
}

.nav-item {
    @apply inline-flex items-center;
}

.desktop-nav-dropdown:hover {
    .desktop-nav-dropdown__panel {
        opacity: 1;
        pointer-events: all;
        transform: translateY(100%);
    }

    .pi-chevron-down {
        @apply -rotate-180;
    }
}

.mobile-nav-item {
    @apply py-4 w-full relative text-left;

    &::after {
        content: '\e907';
        font-family: 'NaturaDentIcon', sans-serif;
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        @apply text-xl;
    }

    @apply border-b border-light-350;
}

.header-logo--icon-only {
    display: block;
}

.header-logo--text {
    display: none;
}

@media (max-width: 400px) {
    .mobile-menu-logo {
        max-width: 120px;
    }
}

@media (min-width: 360px) {
    .header-logo--icon-only {
        display: none;
    }

    .header-logo--text {
        display: block;
    }
}
</style>
