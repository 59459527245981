var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"block text-center subtitle mb-10"},[_c('T',{attrs:{"t":"account.register_account"}})],1),_vm._v(" "),_c('ValidationObserver',{ref:"form",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"w-full",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"grid grid-cols-12 gap-6"},[(_vm.$i18n.locale === 'en')?_c('ValidationProvider',{staticClass:"p-field col-span-12 lg:col-span-6",attrs:{"vid":"first_name","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputLabel',{attrs:{"for":"first_name"}},[_c('T',{attrs:{"t":"form.first_name"}})],1),_vm._v(" "),_c('InputText',{attrs:{"id":"first_name","name":"first_name","type":"text","placeholder":_vm.$t('form.first_name'),"autocomplete":"off"},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),_vm._v(" "),_c('InputError',{attrs:{"errors":errors}})]}}],null,true)}):_vm._e(),_vm._v(" "),_c('ValidationProvider',{staticClass:"p-field col-span-12 lg:col-span-6",attrs:{"vid":"last_name","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputLabel',{attrs:{"for":"last_name"}},[_c('T',{attrs:{"t":"form.last_name"}})],1),_vm._v(" "),_c('InputText',{attrs:{"id":"name","name":"last_name","type":"text","placeholder":_vm.$t('form.last_name'),"autocomplete":"off"},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}}),_vm._v(" "),_c('InputError',{attrs:{"errors":errors}})]}}],null,true)}),_vm._v(" "),(_vm.$i18n.locale === 'hu')?_c('ValidationProvider',{staticClass:"p-field col-span-12 lg:col-span-6",attrs:{"vid":"first_name","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputLabel',{attrs:{"for":"first_name"}},[_c('T',{attrs:{"t":"form.first_name"}})],1),_vm._v(" "),_c('InputText',{attrs:{"id":"first_name_hu","name":"first_name","type":"text","placeholder":_vm.$t('form.first_name'),"autocomplete":"off"},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),_vm._v(" "),_c('InputError',{attrs:{"errors":errors}})]}}],null,true)}):_vm._e(),_vm._v(" "),_c('ValidationProvider',{staticClass:"p-field col-span-12",attrs:{"vid":"email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputLabel',{attrs:{"for":"email"}},[_c('T',{attrs:{"t":"account.your_email_address"}})],1),_vm._v(" "),_c('InputText',{attrs:{"id":"email","name":"email","type":"email","placeholder":_vm.$t('account.your_email_address'),"autocomplete":"off"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('InputError',{attrs:{"errors":errors}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"p-field col-span-12",attrs:{"vid":"password","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputLabel',{attrs:{"for":"password"}},[_c('T',{attrs:{"t":"account.your_password"}})],1),_vm._v(" "),_c('Password',{attrs:{"id":"password","name":"password","toggle-mask":"","feedback":false,"placeholder":_vm.$t('account.your_password'),"autocomplete":"off"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_vm._v(" "),_c('InputError',{attrs:{"errors":errors}})]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"col-span-12"},[_c('ValidationProvider',{staticClass:"mb-2",attrs:{"vid":"accept_privacy_policy","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"p-field field-checkbox"},[_c('Checkbox',{attrs:{"id":"accept_privacy_policy","binary":true},model:{value:(_vm.accept_privacy),callback:function ($$v) {_vm.accept_privacy=$$v},expression:"accept_privacy"}}),_vm._v(" "),_c('label',{attrs:{"for":"accept_privacy_policy"}},[_c('T',{attrs:{"t":"form.data_privacy.text_1"}}),_vm._v(" "),_c('NuxtLink',{staticClass:"p-link",attrs:{"to":_vm.localePath('privacy-policy'),"target":"_blank","rel":"noopener noreferrer"}},[_c('T',{attrs:{"t":"form.data_privacy.text_2"}})],1),_vm._v(" "),_c('T',{attrs:{"t":"form.data_privacy.text_3"}}),_vm._v(" "),_c('NuxtLink',{staticClass:"p-link",attrs:{"to":_vm.localePath('terms-and-conditions'),"target":"_blank","rel":"noopener noreferrer"}},[_c('T',{attrs:{"t":"form.data_privacy.text_4"}})],1),_vm._v(" "),_c('T',{attrs:{"t":"form.data_privacy.text_5"}})],1)],1),_vm._v(" "),_c('InputError',{attrs:{"errors":errors}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"p-field field-checkbox",attrs:{"vid":"newsletter","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Checkbox',{attrs:{"id":"newsletter-subscribe","binary":true},model:{value:(_vm.newsletter),callback:function ($$v) {_vm.newsletter=$$v},expression:"newsletter"}}),_vm._v(" "),_c('label',{attrs:{"for":"newsletter-subscribe"}},[_c('T',{attrs:{"t":"newsletter.subscribe_label"}})],1),_vm._v(" "),_c('InputError',{attrs:{"errors":errors}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"col-span-12 text-center mt-4"},[_c('Button',{attrs:{"type":"submit","label":_vm.$t('account.register_account'),"disabled":_vm.submitting}}),_vm._v(" "),_c('div',{staticClass:"mt-12"},[_c('p',[_c('T',{attrs:{"t":"account.do_you_have_naturadent_account"}})],1),_vm._v(" "),_c('Button',{staticClass:"p-button-text",attrs:{"label":_vm.$t('navigation.sign_in')},on:{"click":_vm.toggle}})],1)],1)],1)])]}}])}),_vm._v(" "),_c('RecaptchaDisclaimer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }