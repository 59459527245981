<template>
    <span class="text-xs max-w-sm block mt-5">
        <span><T t="recaptcha_disclaimer.text_1" /></span>
        <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
            class="font-bold desktop:hover:underline"
        >
            <T t="recaptcha_disclaimer.text_2" />
        </a>
        <span><T t="recaptcha_disclaimer.text_3" /></span>
        <a
            href="https://policies.google.com/terms"
            target="_blank"
            rel="noopener noreferrer"
            class="font-bold desktop:hover:underline"
        >
            <T t="recaptcha_disclaimer.text_4" />
        </a>
        <span><T t="recaptcha_disclaimer.text_5" /></span>
    </span>
</template>

<script>
export default {
    name: 'RecaptchaDisclaimer',
};
</script>

<style scoped></style>
