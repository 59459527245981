<template>
    <div class="flex flex-wrap items-center" :class="{ 'mt-4 lg:mt-6': isOnPageTop }">
        <div class="flex items-center breadcrumb-item"></div>
        <NuxtLink :to="localePath('index')" class="mr-1 breadcrumb-item text-sm"> {{ $t('core.home') }} </NuxtLink>
        <i class="ni ni-angle-arrow-right mx-3 text-green-500 text-lg"></i>
        <template v-for="(item, i) in processedItems">
            <i
                v-if="i !== 0"
                :key="`breadcrumb-item-chevron-${i}`"
                class="ni ni-angle-arrow-right mx-3 text-green-500 text-lg"
            ></i>
            <NuxtLink
                :key="`breadcrumb-item-link-${i}`"
                :to="localePath({ name: item.path, params: $route.params })"
                class="breadcrumb-item mr-1 text-sm"
                :class="item"
            >
                {{ item.label }}
            </NuxtLink>
        </template>
    </div>
</template>

<script>
export default {
    name: 'Breadcrumb',
    props: {
        // items is an array of arrays, containing the page's name and the slug
        // (e.g. [['Services', 'services'], ['Example service', 'example-service']])
        items: {
            type: Array,
            required: true,
        },
        isOnPageTop: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            processedItems: [],
        };
    },
    watch: {
        '$route.path'() {
            this.processedItems = this.getProcessedBreadcrumbItems(...this.items);
        },
    },
    mounted() {
        this.processedItems = this.getProcessedBreadcrumbItems(...this.items);
    },
    methods: {
        getProcessedBreadcrumbItems(...params) {
            const breadcrumbData = [];

            params.forEach((breadcrumbEntityArray) => {
                const breadcrumbItem = {};
                let pathBase = '';

                if (breadcrumbData.length) {
                    pathBase += breadcrumbData[breadcrumbData.length - 1].path;
                    pathBase += '-';
                }

                breadcrumbItem.label = breadcrumbEntityArray[0];
                breadcrumbItem.path = pathBase + breadcrumbEntityArray[1];

                breadcrumbData.push(breadcrumbItem);
            });

            return breadcrumbData;
        },
    },
};
</script>

<style scoped>
.breadcrumb-item {
    &:last-of-type {
        @apply font-HKGrotesk-Bold pointer-events-none;
    }
}
</style>
