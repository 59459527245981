<template>
    <div>
        <span class="block text-center subtitle mb-10"><T t="navigation.sign_in" /></span>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }" slim>
            <form novalidate class="w-full" @submit.prevent="handleSubmit(onSubmit)">
                <div class="grid grid-cols-12 gap-y-0">
                    <ValidationProvider v-slot="{ errors }" vid="email" tag="div" class="p-field col-span-12 mb-6">
                        <label for="email" class="p-input-label"><T t="account.your_email_address" /></label>
                        <InputText
                            id="email"
                            v-model="email"
                            name="email"
                            type="email"
                            :placeholder="$t('account.your_email_address')"
                        />
                        <InputError :errors="errors" />
                    </ValidationProvider>

                    <div class="col-span-12 mb-10">
                        <ValidationProvider v-slot="{ errors }" vid="password" tag="div" class="p-field mb-2">
                            <label for="password" class="p-input-label"><T t="account.your_password" /></label>
                            <Password
                                id="password"
                                v-model="password"
                                name="password"
                                toggle-mask
                                :feedback="false"
                                :placeholder="$t('account.your_password')"
                            />
                            <InputError :errors="errors" />
                        </ValidationProvider>
                        <NuxtLink
                            :to="localePath('forgot-password')"
                            class="p-link text-sm text-green-500 mt-3"
                            @click.native="closeLoginModal"
                        >
                            <T t="account.i_forgot_my_password" />
                        </NuxtLink>
                    </div>

                    <div class="col-span-12 text-center mt-4">
                        <Button
                            type="submit"
                            class="p-button-md/lg w-full md:w-max"
                            :label="$t('navigation.sign_in')"
                            :disabled="submitting"
                        />

                        <div class="mt-5">
                            <p><T t="account.dont_have_account_yet" /></p>
                            <Button
                                :label="$t('account.register')"
                                class="p-button-text font-HKGrotesk-Bold text-green-500 mt-1"
                                @click="toggle"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
export default {
    name: 'RegisterForm',
    props: {
        toggle: {
            type: Function,
            required: true,
        },
        cb: {
            type: Function,
            required: false,
            default: () => {},
        },
    },
    data() {
        return {
            email: '',
            password: '',
            submitting: false,
        };
    },
    methods: {
        async onSubmit() {
            this.submitting = true;
            try {
                await this.$axios
                    .$post('/auth/login', {
                        email: this.email,
                        password: this.password,
                    })
                    .then((res) => {
                        if (res.success === 1) {
                            // currently the "remember me" functionality is not requested, but it's easily done
                            // by setting the following cookie's value to dynamic
                            this.$cookies.set('__naturadent__remember_me', true);

                            this.$auth.logIn(res.user, res.token);
                            this.cb();
                        } else {
                            this.$refs.form.setErrors(res.error);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        this.submitting = false;
                    });
            } catch (err) {
                console.log(err);
            }
        },
        closeLoginModal() {
            this.$store.commit('layout/setIsLoginModalOpen', false);
            document.body.classList.remove('overflow-hidden');
        },
    },
};
</script>

<style scoped>
.text-green-500 {
    color: #00c189 !important;
}
</style>
