<template>
    <div class="flex flex-col">
        <div
            class="
                text-[1.75rem]
                font-HKGrotesk-SemiBold
                text-center
                mb-7
                pb-4
                border-b border-black border-opacity-10
                block
            "
        >
            <T t="generic.appointment_booking" />
        </div>
        <div class="relative h-full">
            <ValidationObserver ref="form" :class="isThankYouMessageVisible && $device.isMobile && 'hidden'" slim>
                <form novalidate class="w-full" @submit.prevent="onSubmit">
                    <div class="grid grid-cols-2 gap-4">
                        <ValidationProvider
                            v-slot="{ errors }"
                            vid="last_name"
                            tag="div"
                            class="col-span-2 lg:col-span-1"
                        >
                            <InputLabel for="last-name" class="p-input-label">
                                <T t="form.last_name" />
                            </InputLabel>
                            <InputText
                                id="last-name"
                                v-model="last_name"
                                name="last_name"
                                type="text"
                                :placeholder="$t('form.last_name_placeholder')"
                                autocomplete="off"
                                @input="triggerGtagManager('last_name', last_name, 1)"
                            />
                            <InputError :errors="errors" />
                        </ValidationProvider>
                        <ValidationProvider
                            v-slot="{ errors }"
                            vid="first_name"
                            tag="div"
                            class="col-span-2 lg:col-span-1"
                        >
                            <InputLabel for="first-name" class="p-input-label">
                                <T t="form.first_name" />
                            </InputLabel>
                            <InputText
                                id="first-name"
                                v-model="first_name"
                                name="first_name"
                                type="text"
                                :placeholder="$t('form.first_name_placeholder')"
                                autocomplete="off"
                                @input="triggerGtagManager('first_name', first_name, 2)"
                            />
                            <InputError :errors="errors" />
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" vid="email" tag="div" class="col-span-2 lg:col-span-1">
                            <InputLabel for="email" class="p-input-label"> <T t="form.email" /> </InputLabel>
                            <InputText
                                id="email"
                                v-model="email"
                                :placeholder="$t('form.email')"
                                type="email"
                                @input="triggerGtagManager('email', email, 3)"
                            />
                            <InputError :errors="errors" />
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" vid="phone" tag="div" class="col-span-2 lg:col-span-1">
                            <InputLabel for="phone" class="p-input-label">
                                <T t="generic.telephone_number" />
                            </InputLabel>
                            <InputText
                                id="phone"
                                v-model="phone"
                                :placeholder="$t('form.telephone_number_placeholder')"
                                type="phone"
                                @input="triggerGtagManager('phone', phone, 4)"
                            />
                            <InputError :errors="errors" />
                        </ValidationProvider>
                        <ValidationProvider
                            v-slot="{ errors }"
                            vid="service"
                            tag="div"
                            class="col-span-2 lg:col-span-1"
                        >
                            <InputLabel for="service" class="p-input-label"> <T t="form.service" /> </InputLabel>
                            <InputText
                                id="service"
                                v-model="service"
                                :placeholder="$t('form.service_placeholder')"
                                type="phone"
                                @input="triggerGtagManager('service', service, 5)"
                            />
                            <InputError :errors="errors" />
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" vid="date" tag="div" class="col-span-2 lg:col-span-1">
                            <InputLabel for="date" class="p-input-label"> <T t="form.date" /> </InputLabel>
                            <Calendar
                                ref="calendar"
                                v-model="date"
                                :date-format="'mm/dd/yy'"
                                autocomplete="off"
                                class="w-full"
                                :disabled-days="[0, 6, 7]"
                                :manual-input="false"
                                :placeholder="$t('form.date_placeholder')"
                                @input="triggerGtagManager('date', date, 6)"
                            />
                            <InputError :errors="errors" />
                        </ValidationProvider>
                        <ValidationProvider
                            v-slot="{ errors }"
                            vid="accept_newsletter"
                            tag="div"
                            class="col-span-2 mt-2"
                        >
                            <div class="p-field field-checkbox">
                                <Checkbox id="accept_newsletter" v-model="accept_newsletter" :binary="true" />
                                <label for="accept_newsletter">
                                    <T t="form.newsletter_checkbox_title"></T>
                                </label>
                            </div>
                            <InputError :errors="errors" />
                        </ValidationProvider>
                        <ValidationProvider
                            v-slot="{ errors }"
                            vid="accept_privacy_policy"
                            tag="div"
                            class="col-span-2"
                        >
                            <div class="p-field field-checkbox">
                                <Checkbox id="accept_privacy_policy" v-model="accept_privacy_policy" :binary="true" />
                                <label for="accept_privacy_policy">
                                    <T t="form.data_privacy.text_1" />
                                    <NuxtLink
                                        :to="localePath('privacy-policy')"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        class="p-link"
                                    >
                                        <T t="form.data_privacy.text_2" />
                                    </NuxtLink>
                                    <T t="form.data_privacy.text_3" />
                                    <NuxtLink
                                        :to="localePath('terms-and-conditions')"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        class="p-link"
                                    >
                                        <T t="form.data_privacy.text_4" />
                                    </NuxtLink>
                                    <T t="form.data_privacy.text_5" />
                                </label>
                            </div>
                            <InputError :errors="errors" />
                        </ValidationProvider>
                        <div class="col-span-2 text-left mt-2">
                            <Button
                                type="submit"
                                :disabled="
                                    !email.length ||
                                    !date ||
                                    !phone.length ||
                                    !first_name.length ||
                                    !last_name.length ||
                                    !accept_privacy_policy ||
                                    canBeSent
                                "
                                :label="$t('form.send_appointment_booking')"
                                class="xs-w-full"
                            />
                        </div>
                        <RecaptchaDisclaimer />
                    </div>
                </form>
            </ValidationObserver>
            <div
                v-if="isThankYouMessageVisible"
                class="
                    absolute
                    top-0
                    -left-1
                    w-[calc(100%+4px))
                    h-[calc(100%+4px)]
                    bg-white bg-opacity-100
                    flex
                    items-center
                    lg:items-start
                    justify-center
                "
            >
                <div>
                    <span class="subtitle mb-4 text-dark-700">
                        <T t="form.booking_appointment_feedback_message_title" />
                    </span>
                    <div class="text-dark-200" v-html="$t('form.booking_appointment_feedback_message_desc')"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RecaptchaDisclaimer from '@/components/Forms/RecaptchaDisclaimer';

export default {
    name: 'AppointmentBookingForm',
    components: {
        RecaptchaDisclaimer,
    },
    data() {
        return {
            first_name: '',
            last_name: '',
            email: '',
            message: '',
            phone: '',
            service: '',
            date: '',
            accept_newsletter: false,
            accept_privacy_policy: false,
            isThankYouMessageVisible: false,
            canBeSent: false,
            selectedTab: '',
            tabs: [
                { title: 'generic.appointment_booking', isActive: true },
                { title: 'form.question', isActive: false },
            ],
            instructions: [
                'form.appointment_booking_instruction_1',
                'form.appointment_booking_instruction_2',
                'form.appointment_booking_instruction_3',
                'form.appointment_booking_instruction_4',
                'form.appointment_booking_instruction_5',
            ],
            debounceTimeout: null,
        };
    },
    async mounted() {
        await this.$recaptcha.init();

        if (
            this.$route.hash === '#appointment-booking' ||
            this.$route.hash === '#idopontfoglalas' ||
            this.$route.hash === ''
        ) {
            this.selectTab({ title: 'generic.appointment_booking' });
        } else {
            this.selectTab({ title: 'form.question' });
        }
        this.$primevue.config.locale = this.$primevue.config.locales[this.$i18n.locale];
    },
    beforeDestroy() {
        this.$recaptcha.destroy();
    },

    methods: {
        async onSubmit() {
            this.canBeSent = true;
            try {
                const token = await this.$recaptcha.execute('appointment_booking');
                await this.$axios
                    .$post(
                        '/recaptcha/validate/appointment/apply',
                        {
                            first_name: this.first_name,
                            last_name: this.last_name,
                            email: this.email,
                            phone: this.phone,
                            service: this.service,
                            date: this.$dayjs(this.date).format('YYYY-MM-DD'),
                            newsletter: this.accept_newsletter ? '1' : '0',
                            privacy: this.accept_privacy_policy ? '1' : '0',
                            reCaptcha: token,
                        },
                        {
                            baseURL: '',
                            headers: {
                                'base-url': this.$axios.defaults.baseURL,
                                'recaptcha-token': token,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.success === 1) {
                            if (this.$i18n.locale === 'hu') {
                                this.$router.push({ hash: '#sikeres-idopontfoglalas' });
                            } else {
                                this.$router.push({ hash: '#successful-appointment-booking' });
                            }
                            this.canBeSent = false;
                            this.isThankYouMessageVisible = true;
                        } else {
                            this.$refs.form.setErrors(res.error);
                        }
                        const gtagAttributes = {
                            page_path: this.$route.path,
                            variable_1: '5678',
                            variable_2: 'Success',
                        };
                        this.$gtm.push({ event: 'form_submit', ...gtagAttributes });
                    });

                await this.$recaptcha.reset();
            } catch (err) {
                console.log(err);
            }
        },
        changeHash(hash) {
            if (this.$i18n.locale === 'hu') {
                this.$router.push({ hash: hash.hu });
            } else {
                this.$router.push({ hash: hash.en });
            }
        },
        selectTab(selectedTab) {
            const gtagAttributes = {
                page_path: this.$route.path,
                variable_1: '5679',
                variable_3: 'xxxx',
            };
            this.tabs.forEach((tab) => {
                tab.isActive = tab.title === selectedTab.title;
                this.selectedTab = selectedTab.title;
                if (selectedTab.title === 'generic.appointment_booking') {
                    this.changeHash({ en: '#appointment-booking', hu: '#idopontfoglalas' });
                } else {
                    this.changeHash({ en: '#contact', hu: '#kapcsolatfelvetel' });
                    this.$gtm.push({ event: 'form_contact', ...gtagAttributes });
                }
            });
        },
        triggerGtagManager(key, value, index) {
            const gtagAttributes = {
                page_path: this.$route.path,
                variable_1: '5678',
            };

            if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
            this.debounceTimeout = setTimeout(() => {
                gtagAttributes[key] = value;
                this.$gtm.push({ event: `form_filled_${index}`, ...gtagAttributes });
            }, 500);
        },
    },
};
</script>

<style scoped>
.tab {
    @apply opacity-60;
    &::before {
        content: '';
        @apply absolute w-3/5 max-h-0 h-full transition rounded-tl-lg rounded-tr-lg bg-green-500 bottom-0 block;
        transition: 0.25s ease;
    }
}
.is-active {
    @apply opacity-100;
    &::before {
        @apply max-h-1;
    }
}
</style>
