export default {
    head() {
        const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });

        const filteredHrefLangLinks = i18nHead.link.filter((link) => {
            if (this.getRouteBaseName() === 'index') {
                return true;
            }

            return !(link.rel === 'alternate' && link.href === this.$config.baseURL + '/');
        });

        return {
            htmlAttrs: i18nHead.htmlAttrs,
            meta: [...i18nHead.meta],
            link: [...filteredHrefLangLinks],
        };
    },
};
