export default ({ $config, $device, $gtm }, inject) => {
    // Resources for consent mode
    // https://www.youtube.com/watch?v=MqAEbshMv84
    // https://developers.google.com/tag-platform/security/guides/consent?consentmode=advanced#gtag.js_2
    // https://developers.google.com/tag-platform/security/guides/consent-debugging

    window.dataLayer = window.dataLayer || [];

    function gtag() {
        window.dataLayer.push(arguments);
    }

    const savedConsent = JSON.parse(localStorage.getItem('consent'));

    gtag('consent', 'default', {
        security_storage: 'granted',
        functionality_storage: 'granted',
        analytics_storage: savedConsent?.statistics ? 'granted' : 'denied',
        personalization_storage: savedConsent?.preferences ? 'granted' : 'denied',
        ad_storage: savedConsent?.marketing ? 'granted' : 'denied',
        ad_user_data: savedConsent?.marketing ? 'granted' : 'denied',
        ad_personalization: savedConsent?.marketing ? 'granted' : 'denied',
    });

    inject('gtag', gtag);

    if ($config.nuxtENV === 'production') {
        setTimeout(() => {
            if (/naturadent\.hu/.test(window.location.origin)) {
                $gtm.init();
            }
        }, 500);
    }
};
