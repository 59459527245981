<template>
    <div
        v-if="getRouteBaseName() === 'blog-article' && $store.state.blog.doesProgressBarHaveTransition"
        class="absolute w-full h-1 bottom-0 left-0 z-50 bg-light-350"
    >
        <div
            class="absolute bottom-0 left-0 h-1 bg-green-500"
            :style="`transition: ${
                $store.state.blog.doesProgressBarHaveTransition ? '.1s all' : 'unset'
            }; width: ${blogArticleReadProgressPercentile}%`"
        ></div>
    </div>
</template>

<script>
export default {
    name: 'BlogArticleReadProgressBar',
    computed: {
        blogArticleReadProgressPercentile() {
            const _ret = (this.$store.state.globals.scrollY / this.$store.state.blog.currentArticleHeight) * 100;
            return _ret > 100 ? 100 : _ret;
        },
    },
};
</script>

<style scoped></style>
