<template>
    <Transition name="error-fade">
        <div v-if="errors.length" class="text-left">
            <span class="p-error">{{ errors[0] }}</span>
        </div>
    </Transition>
</template>

<script>
export default {
    name: 'InputError',
    props: {
        errors: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.error-fade {
    &-enter,
    &-leave-to {
        @apply opacity-0;
        @apply -mt-5;
    }

    &-enter-active,
    &-leave-active {
        @apply transition-all;
    }

    &-enter-to,
    &-leave {
        @apply opacity-100;
        @apply mt-0;
    }
}
</style>
