<template>
    <Portal selector="body">
        <div :id="isOpen ? 'modal-wrapper-active' : ''" ref="modalWrapper">
            {{/* the modal itself */}}
            <Transition name="translate">
                <div v-if="isOpen" class="fixed w-full h-full top-0 left-0 nicescroll md:overflow-scroll z-50">
                    {{/* modal background */}}
                    <div
                        class="fixed top-0 left-0 w-full h-full bg-dark-600 opacity-80 z-60"
                        :class="{ 'pointer-events-none': arePointerEventsDisabledOnBgOverlay }"
                        @click="closeModal"
                    ></div>
                    <div
                        class="
                            scroll-container__inner
                            absolute
                            md:container
                            grid grid-cols-12
                            w-full
                            h-full
                            left-1/2
                            bottom-0
                            transition-all
                            md:bottom-1/2
                            translate-y-0
                            md:translate-y-1/2
                            -translate-x-1/2
                            md:h-auto md:max-h-screen
                            pointer-events-none
                        "
                        style="max-height: 90vh"
                    >
                        <div class="hidden md:block h-4 col-span-12"></div>

                        <div
                            class="
                                bg-white
                                col-span-12 col-start-1
                                rounded-tl-2xl rounded-tr-2xl
                                md:rounded-2xl
                                relative
                                pointer-events-auto
                            "
                            :class="{
                                'md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3 xl:col-span-8 xl:col-start-3':
                                    !isWide,
                                'overflow-hidden': isContentOverflowHidden,
                                'overflow-visible': !isContentOverflowHidden,
                            }"
                        >
                            {{/* close button is added like this because the prime vue button icon was always off-center :( */}}
                            <button
                                class="close-button z-10"
                                name="Close modal"
                                aria-label="Close modal"
                                @click="closeModal"
                            >
                                <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M12.7099 11.2902C12.8982 11.4785 13.004 11.7339 13.004 12.0002C13.004 12.2665 12.8982 12.5219 12.7099 12.7102C12.5216 12.8985 12.2662 13.0043 11.9999 13.0043C11.7336 13.0043 11.4782 12.8985 11.2899 12.7102L6.99994 8.41019L2.70994 12.7102C2.52164 12.8985 2.26624 13.0043 1.99994 13.0043C1.73364 13.0043 1.47825 12.8985 1.28994 12.7102C1.10164 12.5219 0.99585 12.2665 0.99585 12.0002C0.99585 11.7339 1.10164 11.4785 1.28994 11.2902L5.58994 7.00019L1.28994 2.71019C1.10164 2.52188 0.99585 2.26649 0.99585 2.00019C0.99585 1.73388 1.10164 1.47849 1.28994 1.29019C1.47825 1.10188 1.73364 0.996094 1.99994 0.996094C2.26624 0.996094 2.52164 1.10188 2.70994 1.29019L6.99994 5.59019L11.2899 1.29019C11.4782 1.10188 11.7336 0.996094 11.9999 0.996094C12.2662 0.996094 12.5216 1.10188 12.7099 1.29019C12.8982 1.47849 13.004 1.73388 13.004 2.00019C13.004 2.26649 12.8982 2.52188 12.7099 2.71019L8.40994 7.00019L12.7099 11.2902Z"
                                        fill="#113E51"
                                    />
                                </svg>
                            </button>
                            <div
                                class="
                                    modal-content
                                    nicescroll
                                    py-10
                                    h-full
                                    md:h-auto
                                    overflow-y-auto
                                    md:overflow-y-visible
                                    grid
                                    px-12
                                "
                                :class="isWide ? 'xl:px-16' : 'xl:px-24'"
                            >
                                <slot></slot>
                            </div>
                        </div>
                        <div class="hidden md:block h-4 col-span-12"></div>
                    </div>
                </div>
            </Transition>
        </div>
    </Portal>
</template>

<script>
export default {
    name: 'BaseModal',
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        isWide: {
            type: Boolean,
            required: false,
            default: false,
        },
        close: {
            type: Function,
            required: true,
        },
        isContentOverflowHidden: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            keyPressHandler: this.handleKeyPress,
            arePointerEventsDisabledOnBgOverlay: true,
        };
    },
    watch: {
        isOpen() {
            if (this.isOpen) {
                setTimeout(() => {
                    this.arePointerEventsDisabledOnBgOverlay = false;
                }, 250);
            }
        },
    },
    mounted() {
        window.addEventListener('keyup', this.keyPressHandler);
    },
    destroyed() {
        window.removeEventListener('keyup', this.keyPressHandler);
    },
    methods: {
        closeModal() {
            this.arePointerEventsDisabledOnBgOverlay = true;
            this.close();
        },
        handleKeyPress(e) {
            if (this.isOpen && e.key === 'Escape') {
                this.arePointerEventsDisabledOnBgOverlay = true;
                this.close();
            }
        },
    },
};
</script>

<style scoped>
.translate {
    &-enter-active,
    &-leave-active {
        @apply transition-all ease-in-out;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
        > .scroll-container__inner {
            transform: translate(-50%, calc(100%));
            @media (min-width: 768px) {
                transform: translate(-50%, calc(50% - 2rem));
            }
        }
    }
}

.close-button {
    @apply absolute w-6 h-6 top-4 right-4;
    transition: all 0.3s;

    svg {
        @apply absolute w-4 h-4 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;

        path {
            transition: fill 0.3s;
        }
    }
}

.modal-content {
    max-height: 90vh;
    @media (min-width: 768px) {
        max-height: unset;
    }
    @media (max-width: 768px) {
        @apply container;
    }
}

/*
.scroll-container__inner {
    @media (max-width: 767px) {
        @apply max-h-screen-85;
    }
}
*/
</style>
