export const state = () => ({
    scrollY: 0,
    windowWidth: 0,
    prevWindowWidth: 0,
    lastScrollDirection: 'down',
    isAppointmentBookingCardExist: false,
    isHeaderVisible: false,
});

export const mutations = {
    setScrollY(state, payload) {
        state.scrollY = payload;
    },
    setWindowWidth(state, payload) {
        state.windowWidth = payload;
    },
    setHeaderVisible(state, payload) {
        state.isHeaderVisible = payload;
    },
    setPrevWindowWidth(state, payload) {
        state.prevWindowWidth = payload;
    },
    setLastScrollDirection(state, payload) {
        state.lastScrollDirection = payload;
    },
    checkAppointmentBookingCardIsExist(state, payload) {
        state.isAppointmentBookingCardExist = payload;
    },
};
