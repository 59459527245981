<template>
    <div id="partners-carousel-container" class="swiper-container relative mt-10 mb-14">
        <h2 class="title-2 text-center border-b border-opacity-10 border-dark-500 sm:text-left pb-7 mb-8">
            <T t="generic.partner_and_brands_we_work_with" />
        </h2>
        <div class="partners-carousel-wrapper relative">
            <div id="partners-carousel" class="swiper">
                <div class="swiper-wrapper items-center">
                    <div v-for="partner in partnerLogos" :key="partner.id" class="swiper-slide">
                        <a v-if="partner.url" :href="partner.url" target="_blank" rel="noopener noreferrer">
                            <img :src="partner.image" :alt="`${partner.title} logo`" style="transform: scale(0.8)" />
                        </a>
                        <img v-else :src="partner.image" :alt="`${partner.title} logo`" style="transform: scale(0.8)" />
                    </div>
                </div>
            </div>
        </div>
        <div class="hidden md:flex justify-between mt-8 sm:mt-0 sm:absolute sm:top-0 sm:right-0">
            <div class="swiper-button-prev sm:mr-4"></div>
            <div class="swiper-button-next"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PartnersCarousel',
    data() {
        return {
            partnerLogos: null,
        };
    },
    async fetch() {
        await this.$axios.get('/partner').then((res) => {
            this.partnerLogos = res.data.data;
        });
    },
    mounted() {
        this.partnersCarousel = new this.$swiper('#partners-carousel', {
            slidesPerView: 3,
            spaceBetween: 20,
            lazy: true,
            resistance: true,
            resistanceRatio: 0,
            speed: 750,
            breakpoints: {
                520: {
                    slidesPerView: 3,
                },
                1024: {
                    slidesPerView: 4,
                },
                1280: {
                    slidesPerView: 5,
                },
                1921: {
                    slidesPerView: 6,
                },
            },
            navigation: {
                nextEl: '#partners-carousel-container .swiper-button-next',
                prevEl: '#partners-carousel-container .swiper-button-prev',
            },
            loop: true,
            autoplay: {
                delay: 4000,
            },
        });
    },
    beforeDestroy() {
        this.partnersCarousel.destroy();
    },
};
</script>

<style scoped>
.partners-carousel-wrapper {
    &::before {
        content: '';
        @media (max-width: 768px) {
            @apply w-10 h-full right-0 top-0 block absolute z-10;
            background: linear-gradient(270deg, white 0%, rgba(255, 255, 255, 0) 100%);
        }
    }
}
</style>
