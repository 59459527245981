export default ({ app, $device, req, $config, route, i18n }) => {
    //
    //
    // Find out host url from x-forwarded-host header (must be configured in apache properly)
    let host;

    if (process.server) {
        host = req.headers['x-forwarded-host'];
    } else {
        host = window.location.origin;
    }

    if ($config.nodeENV !== 'development' || !/^https/.test(host)) {
        host = 'https://' + host;
    }

    //
    //
    // Add meta tags
    const meta = [];

    meta.push({
        hid: 'description',
        name: 'description',
        content: i18n.t('homepage.hero.fallback_title'),
    });

    meta.push({
        hid: 'og:description',
        property: 'og:description',
        content: i18n.t('homepage.hero.fallback_title'),
    });

    meta.push({
        property: 'og:url',
        content: $config.baseURL + route.fullPath,
    });

    meta.push({
        hid: 'og:image',
        property: 'og:image',
        content: `${host}/og-image.jpg`,
    });

    meta.push({
        hid: 'og:image:url',
        property: 'og:image:url',
        content: `${host}/og-image.jpg`,
    });

    meta.push({
        property: 'og:image:type',
        content: 'image/jpg',
    });

    meta.push({
        name: 'robots',
        hid: 'robots',
        content: $config.nuxtENV === 'production' && host && !/melkweg|mlkwg/.test(host) ? 'all' : 'noindex, nofollow',
    });

    //
    //
    // Add script tags
    const script = [];

    if (process.env.NUXT_NODE_ENV === 'production' && !$device.isCrawler) {
        const SCRIPT_PATHS = ['/scripts/hotjar.js'];

        SCRIPT_PATHS.forEach((src) => {
            script.push({
                src,
                async: true,
            });
        });
    }

    app.head.script.push(...script);
    app.head.meta.push(...meta);
};
