export const state = () => ({
    loggedIn: false,
    user: null,
    token: null,
});

export const mutations = {
    setLoggedInStatus(state, payload) {
        state.loggedIn = payload;
    },
    setUser(state, payload) {
        state.user = payload;
    },
    updateUser(state, payload) {
        Object.keys(payload).forEach((key) => {
            state.user[key] = payload[key];
        });
    },
    setToken(state, payload) {
        console.log('token now is set');
        state.token = payload;
    },
    reset(state) {
        state.loggedIn = false;
        state.user = null;
        state.token = null;
        state.autoLogin = false;
    },
};
