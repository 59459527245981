<template>
    <a v-if="external" :target="isTargetBlank && '_blank'" class="p-button p-component" :href="to">
        <span class="p-button-label">{{ label }}</span>
    </a>
    <NuxtLink v-else class="p-button p-component" :to="to">
        <span class="p-button-label">{{ label }}</span>
    </NuxtLink>
</template>

<script>
export default {
    name: 'ButtonLink',
    props: {
        to: {
            type: String,
            required: true,
        },
        external: {
            type: Boolean,
            required: false,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
        isTargetBlank: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped></style>
